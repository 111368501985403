import letsdothat from "../assets/letsdothat.jpg";


export const ProjectList = [
    {
        name: "Lets Do That",
        image: letsdothat,
        skills: "Django, React, Docker, YelpAPI"

    },
    
]