import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";

function Experience() {
    return (
        <div className="experience">
            <VerticalTimeline lineColor="#aadedb">
                <VerticalTimelineElement 
                    className="vertical-timeline-element--education"
                    date="2010 -2012"
                    iconStyle={{ background: "#f5a1d0", color: "#fff" }}
                    icon={<SchoolIcon />}>
                    <h3 className="vertical-timeline-element-title"> San Jose State University, San Jose, CA</h3>
                    <h4 className="vertical-timeline-element-subtitle">Bachelor's Degree</h4>
                    <p>Child Development</p>
                </VerticalTimelineElement>
            <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2013 - 2014"
        iconStyle={{ background: "#44CFCB", color: "#fff" }}
        icon={<WorkIcon />}
    >
        <h3 className="vertical-timeline-element-title">
        Family Advocate and Spanish Translator -
        Wu Yee Children's Services
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
        San Francisco, CA
        </h4>
        <p>Worked in several roles at a Headstart for Early Child Development including:
            Family Advocate and On-Call Translator in native Spanish and provided resources
            and educational support for families and teachers.</p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2021 - 2022"
          iconStyle={{ background: "#9AE19D", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
          Professional Organizer Assistant - Ashley Kiely Living
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Portland, OR
          </h4>
          <p>
          Worked directly with the CEO to organize and plan various spaces including homes and warehouses.
          </p>
        </VerticalTimelineElement>
                <VerticalTimelineElement 
                    className="vertical-timeline-element--education"
                    date="2022 - 2022"
                    iconStyle={{ background: "#8D91C7", color: "#fff" }}
                    icon={<SchoolIcon />}>
                    <h3 className="vertical-timeline-element-title">
                    Hack Reactor, Online</h3>
                    <h4 className="vertical-timeline-element-subtitle">Certificate</h4>
                    <p> Software Engineer Immersive</p>
                </VerticalTimelineElement>
                

       
            </VerticalTimeline>
        </div>
    )
}

export default Experience
