import React from 'react'
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
        <div className="socialmedia"></div>
        <div>
            <InstagramIcon />
            <TwitterIcon />
            <LinkedInIcon />
        </div>
        <p> &copy; 2023 perlaportfolio.com</p>
    </div>
  );
}

export default Footer